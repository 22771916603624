import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb, {
  PageBreadcrumbItem
} from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
  EpochLeaderboard,
  VortexDataContext
} from 'providers/VortexDataProvider';
import EpochLeaderboardTable, {
  createEpochLeaderboardTableColumns
} from 'components/tables/EpochLeaderboardTable';

export const leaderboardBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Cluster',
    url: '#!'
  },
  {
    label: 'Vote Stats',
    active: true
  }
];

interface SelectedEpoch {
  current: boolean;
  epoch?: number;
}

const ClusterVoteStats = () => {
  const context = useContext(VortexDataContext);
  const [selectedEpoch, setSelectedEpoch] = useState<SelectedEpoch>({
    current: true
  });
  const IDEAL_RECORD_KEY = '11111111111111111111111111111111';

  useEffect(() => {
    if (
      context?.epochLeaderboardState &&
      !context.epochLeaderboardState.isLoading &&
      !context.epochLeaderboardState.error
    ) {
      if (selectedEpoch.epoch === undefined) {
        if (!context.epochLeaderboardState.data.size) {
          context.epochLeaderboardState.fetchData();
        }
      } else if (!selectedEpoch.current && !!selectedEpoch.epoch) {
        if (!context.epochLeaderboardState.data.has(selectedEpoch.epoch)) {
          context.epochLeaderboardState.fetchData(selectedEpoch.epoch);
        }
      }
    }
  }, [context, selectedEpoch]);

  const { resolvedEpoch, leaderboardData } = useMemo(() => {
    if (!context || context.epochLeaderboardState.data.size === 0)
      return {
        resolvedEpoch: undefined,
        leaderboardData: undefined
      };

    if (selectedEpoch.epoch !== undefined) {
      return {
        resolvedEpoch: selectedEpoch.epoch,
        leaderboardData: context.epochLeaderboardState.data.get(
          selectedEpoch.epoch
        )
      };
    }

    const [currentEpoch, data] = context.epochLeaderboardState.data
      .entries()
      .next().value;
    return {
      resolvedEpoch: currentEpoch,
      leaderboardData: data as EpochLeaderboard
    };
  }, [context, selectedEpoch]);

  const computedTableColumns = useMemo(() => {
    const idealNumVotes = leaderboardData?.records.find(
      record => record.voteAddress === IDEAL_RECORD_KEY
    )?.votedSlots;
    return createEpochLeaderboardTableColumns(idealNumVotes);
  }, [leaderboardData]);

  const tableData = useMemo(() => {
    return leaderboardData?.records || [];
  }, [leaderboardData]);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedEpoch({
      current: false,
      epoch: Number.parseInt(event.target.value)
    });
  };

  const epochOptions = useMemo(() => {
    if (!context || context.epochLeaderboardState.data.size === 0)
      return [
        {
          label: 'Current Epoch',
          value: undefined
        }
      ];

    // assume max is current
    let maxFetchedEpoch = 0;
    for (let blah of context.epochLeaderboardState.data) {
      maxFetchedEpoch = Math.max(maxFetchedEpoch, blah[0]);
    }

    let options = [];
    for (let i = Math.max(0, maxFetchedEpoch - 9); i <= maxFetchedEpoch; i++) {
      let label =
        i === maxFetchedEpoch
          ? `Current Epoch (${maxFetchedEpoch})`
          : `Epoch ${i}`;
      options.push({
        label,
        value: i
      });
    }
    options.reverse();
    return options;
  }, [context]);

  const epochTable = useAdvanceTable({
    data: tableData,
    columns: computedTableColumns,
    pageSize: 50,
    pagination: true,
    sortable: true,
    selection: false
  });

  if (!context) return <div>Loading...</div>;

  let { epochLeaderboardState } = context;

  if (selectedEpoch.current && epochLeaderboardState.isLoading)
    return <div>Loading...</div>;
  if (epochLeaderboardState.error)
    return <div>Error: {epochLeaderboardState.error}</div>;

  return (
    <div>
      <PageBreadcrumb items={leaderboardBreadcrumbItems} />
      <div className="mb-9">
        <Row className="align-items-start justify-content-between mb-4 g-3">
          <Col xs="auto">
            <h3>Voting Leaderboard</h3>
            <p className="text-body-tertiary lh-sm mb-0">
              Vote accounts ranked by voted slots and earned TVC credits
            </p>
          </Col>
          <Col xs={12} sm={4}>
            <Form.Select
              size="sm"
              onChange={handleSelectChange}
              value={resolvedEpoch}
            >
              {epochOptions.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>

        <AdvanceTableProvider {...epochTable}>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <EpochLeaderboardTable />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default ClusterVoteStats;
