import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const incomeBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Pages',
    url: '#!'
  },
  {
    label: 'Income',
    active: true
  }
];

export type IncomeDetails = {
  baseFees: number;
  priorityFees: number;
  mevTips: number;
};

export type BlockIncome = {
  slot: number;
  leader: string | null;
  income: IncomeDetails | null;
};

export type EpochIncome = {
  epoch: number;
  stake: number;
  totalSlots: number;
  confirmedSlots: number;
  skippedSlots: number;
  totalIncome: IncomeDetails;
  medianIncome: IncomeDetails;
  minIncome: IncomeDetails;
  maxIncome: IncomeDetails;
};
