import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useContext, useEffect, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { incomeBreadcrumbItems } from 'data/income';
import { VortexDataContext } from 'providers/VortexDataProvider';
import EpochIncomeTable, {
  createEpochIncomeTableColumns
} from 'components/tables/EpochIncomeTable';
import BlockIncomeChart from 'components/charts/e-charts/BlockIncomeChart';

const Income = () => {
  const context = useContext(VortexDataContext);
  const IDEAL_RECORD_KEY = '11111111111111111111111111111111';

  useEffect(() => {
    if (
      context?.epochIncomeState &&
      !context.epochIncomeState.isLoading &&
      !context.epochIncomeState.data.size &&
      !context.epochIncomeState.error
    ) {
      context.epochIncomeState.fetchData(
        '5ZjxMYBbnKd4VFxLjAChSWMTeQ96147HnxZvQJxUseHV',
        611,
        636
      );
      context.epochIncomeState.fetchData(IDEAL_RECORD_KEY, 611, 636);
    }
  }, [context]);

  const clusterData =
    context?.epochIncomeState.data.get(IDEAL_RECORD_KEY) || [];
  const computedTableColumns = useMemo(() => {
    return createEpochIncomeTableColumns(clusterData);
  }, [clusterData]);

  const epochTable = useAdvanceTable({
    data:
      context?.epochIncomeState.data.get(
        '5ZjxMYBbnKd4VFxLjAChSWMTeQ96147HnxZvQJxUseHV'
      ) || [],
    columns: computedTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  if (!context) return <div>Loading...</div>;

  let { epochIncomeState } = context;

  if (epochIncomeState.isLoading) return <div>Loading...</div>;
  if (epochIncomeState.error) return <div>Error: {epochIncomeState.error}</div>;

  return (
    <div>
      <PageBreadcrumb items={incomeBreadcrumbItems} />
      <div className="mb-9">
        <Row className="justify-content-between align-items-center my-4 g-3">
          <Col xs="auto">
            <h3>Median Block Income</h3>
            <p className="text-body-tertiary lh-sm mb-0">
              Median fees and tips received per block
            </p>
          </Col>
        </Row>

        <BlockIncomeChart />

        <Row className="justify-content-between align-items-center my-4 g-3">
          <Col xs="auto">
            <h3>Epoch Income History</h3>
            <p className="text-body-tertiary lh-sm mb-0">
              Median fees and tips received per block
            </p>
          </Col>
        </Row>

        <AdvanceTableProvider {...epochTable}>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <EpochIncomeTable />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default Income;
