import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const voteRecordBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Pages',
    url: '#!'
  },
  {
    label: 'Vote Record',
    active: true
  }
];
