import { Nav } from 'react-bootstrap';
import ThemeToggler from 'components/common/ThemeToggler';

const NavItemsSlim = () => {
  return (
    <div className="navbar-nav navbar-nav-icons flex-row">
      <Nav.Item>
        <ThemeToggler slim />
      </Nav.Item>
    </div>
  );
};

export default NavItemsSlim;
